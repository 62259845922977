import React, { useEffect, useState, useCallback } from 'react';
import logo from './logo_lpp.png';
import './App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import * as XLSX from 'xlsx';

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { Select, MenuItem } from '@material-ui/core';
import styled from 'styled-components';

import { Button } from '@material-ui/core';
import dayjs from 'dayjs'

const Row = styled.div`
  text-align: left;
`;

const Cell = styled.span`
  display: inline-block;
  width: 200px;
  text-align: left;
`;

function App() {
  const [start, setStart] = useState(dayjs().startOf('month').toDate())
  const [end, setEnd] = useState(dayjs().toDate())
  const [deviceData, setDeviceData] = useState([])
  const [timeData, setTimeData] = useState([])
  const [nb, setNb] = useState(1)
  const [timeRange, setTimeRange] = useState('3')
  const [visitsAverageDuration, setVisitsAverageDuration] = useState(0)
  const [visitsTotalDuration, setVisitsTotalDuration] = useState(0)
  const [visitsTotalCount, setVisitsTotalCount] = useState(0)

  const server = "https://stats.lespierresparlent.com";
  //const server = "http://localhost:3000";

  const monumentId = window.location.href.substr(1).split("=")[1]

  useEffect(() => {
    axios.post(`${server}/v1/access_stats/`, { start, end, monument_id: monumentId }).then(result => {
      setNb(result.data.reduce((prev, curr) => prev + parseInt(curr.count), 0))
      setDeviceData(result.data)
    })
  }, [start, end, monumentId])

  const getDurationFromChildren = useCallback((parent, field) => {
    let duration = 0;
    if (parent.sons) {
      parent.sons.forEach(son => {
        let sonDuration = son.sons ? getDurationFromChildren(son, field) : son.mainStat[field] || 0;
        duration += (sonDuration || 0)
      })
    }
    parent.mainStat[field] = duration;
    return duration;
  }, [])

  const format = (duration) => {
    let minutes = Math.floor(duration / 60);
    let hours = Math.floor(minutes / 60);
    return (hours ? (hours + "h") : '') + (minutes ? minutes % 60 + "m" : '') + Math.floor(duration % 60) + "s";
  }

  const getCountFromChildren = useCallback((parent) => {
    let count = 0;
    if (parent.sons) {
      parent.sons.forEach(son => {
        let sonCount = son.sons ? getCountFromChildren(son) : son.mainStat.count || 0;
        count = Math.max(count, sonCount);
      })
    }
    return count;
  }, [])

  const exportRow = row => {
    if (row.sons) {
      return { name: row.mainStat.name, data: row.sons.map(son => exportRow(son)) }
    } else {
      return [row.mainStat.name, parseInt(row.mainStat.total_duration / row.mainStat.count, 10), row.mainStat.total_duration, row.mainStat.count]
    }
  }

  const exportData = () => {
    let data = exportRow(timeData);
    const wb = XLSX.utils.book_new();
    data.data.forEach((elt, idx) => {
      const ws = XLSX.utils.aoa_to_sheet(elt.data);
      XLSX.utils.book_append_sheet(wb, ws, elt.name.substr(0, 30));
    });

    XLSX.writeFile(wb, 'export.xlsx');
    return true;
  }

  const getAverageDurationFromChildren = useCallback((data) => {
    return data.mainStat.total_duration && data.mainStat.count ? data.mainStat.total_duration / data.mainStat.count : 0;
  }, []);

  useEffect(() => {
    let visitsAverageDuration = 0;
    let visitsTotalDuration = 0;
    let visitsTotal = 0;
    axios.post(`${server}/v1/time_stats`, { start, end, monument_id: monumentId }).then(result => {
      result.data.sons.forEach(elt => {
        if (elt.sons) {
          elt.mainStat.total_duration = getDurationFromChildren(elt, 'total_duration');
          visitsTotalDuration += elt.mainStat.total_duration
        }
        if (elt.sons) {
          elt.mainStat.count = getCountFromChildren(elt);
          visitsTotal += elt.mainStat.count
        }
        if (elt.sons) {
          elt.mainStat.averageDuration = getAverageDurationFromChildren(elt);
          visitsAverageDuration += elt.mainStat.averageDuration
        }
      })
      setTimeData(result.data)
      setVisitsAverageDuration(visitsAverageDuration / result.data.sons.length)
      setVisitsTotalDuration(visitsTotalDuration)
      setVisitsTotalCount(visitsTotal)
    })
  }, [end, getAverageDurationFromChildren, getCountFromChildren, getDurationFromChildren, monumentId, start])


  const getTotalDurationFromChildren = useCallback((data, suffix) => {
    console.log("ICII", `total_duration${suffix ? suffix : ''}`, data.mainStat[`total_duration${suffix ? suffix : ''}`])
    return data.mainStat[`total_duration${suffix ? suffix : ''}`] ? data.mainStat[`total_duration${suffix ? suffix : ''}`] : ''
  }, []);

  const getTree = (data, niv) => {
    let result = (
      <>
        <TreeItem key={`level_${niv}`} className={`level_${niv}`} nodeId={`level_${niv}_${data.mainStat.name}`}
          label={<Row>
            <Cell>
              {data.mainStat.name}
            </Cell>
            <Cell>
              {format(getAverageDurationFromChildren(data))}
            </Cell>
            <Cell>
              {format(getTotalDurationFromChildren(data))}
            </Cell>
            <Cell>
              {data.mainStat.count || 0}
            </Cell>
            <Cell>
              {format(getTotalDurationFromChildren(data, "_m"))}
            </Cell>
            <Cell>
              {data.mainStat.count_m || 0}
            </Cell>
            <Cell>
              {format(getTotalDurationFromChildren(data, "_y"))}
            </Cell>
            <Cell>
              {data.mainStat.count_y || 0}
            </Cell>
          </Row>} >
          {data.sons && data.sons.map(data => getTree(data, niv + 1))}
        </TreeItem>
      </>)

    return result
  }

  const handleChange = (event) => {
    setTimeRange(event.target.value);
    let start = dayjs().startOf("day")
    let end = dayjs().endOf("day")
    switch (event.target.value) {
      case "1":
        start = start.add(-1, "day").startOf("day")
        end = start.endOf("day")
        break;
      case "2":
        start = start.add(-1, "month").startOf("month")
        end = start.endOf("month")
        break;
      case "3":
        start = start.startOf("month")
        break;
      case "4":
        start = start.add(-1, "year").startOf("year")
        end = start.endOf("year")
        break;
      case "5":
        start = start.startOf("year")
        break;
      default: console.log(event.target.value + "not handled");
    }
    setStart(start.toDate())
    setEnd(end.toDate())
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <DatePicker
        selected={start}
        onChange={(date) => setStart(dayjs(date).startOf("day").toDate())}
        selectsStart
        startDate={start}
        endDate={end}
        locale={"fr"}
        dateFormat={"dd/MM/yyyy"}
        todayButton={"Aujourd'hui"}
      />
      &nbsp;-&nbsp;
      <DatePicker
        selected={end}
        onChange={(date) => setEnd(dayjs(date).endOf("day").toDate())}
        selectsEnd
        startDate={start}
        endDate={end}
        minDate={start}
        locale={"fr"}
        dateFormat={"dd/MM/yyyy"}
        todayButton={"Aujourd'hui"}
      />
      &nbsp;-&nbsp;
      <Select
        labelId="stats-time-range-label"
        id="stats-time-range"
        value={timeRange}
        onChange={handleChange}
      >
        <MenuItem value="1">Hier</MenuItem>
        <MenuItem value="2">Mois dernier</MenuItem>
        <MenuItem value="3">Mois en cours</MenuItem>
        <MenuItem value="4">Année dernière</MenuItem>
        <MenuItem value="5">Année en cours</MenuItem>
      </Select>
      <table>
        <thead>
          <tr>
            <th>Support</th>
            <th>Nombre</th>
            <th>Pourcentage</th>
          </tr>
        </thead>
        <tbody>
          {deviceData.map((data) => (
            <tr>
              <td>{data.platform}</td>
              <td>{data.count}</td>
              <td>{parseInt((data.count / nb) * 100)} %</td>
            </tr>
          ))}
          <tr>
            <td>Tous</td>
            <td>{nb}</td>
            <th>100 %</th>
          </tr>
        </tbody>
      </table>
      <Button onClick={() => exportData()} >Exporter</Button>

      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={['level_0']}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        <TreeItem key={`level_0`} className={`level_0`} nodeId={`level_0`}
          label={<Row>
            <Cell>
              <b>Eglise</b>
            </Cell>
            <Cell>
              <b>Durée moyenne</b>
            </Cell>
            <Cell>
              <b>Durée totale</b>
            </Cell>
            <Cell>
              <b>Nombre de visites</b>
            </Cell>
            <Cell>
              <b>Durée totale (m-1)</b>
            </Cell>
            <Cell>
              <b>Nombre de visites (m-1)</b>
            </Cell>
            <Cell>
              <b>Durée totale (a-1)</b>
            </Cell>
            <Cell>
              <b>Nombre de visites (a-1)</b>
            </Cell>
          </Row>} >
          {timeData.sons && timeData.sons.map(data => { return getTree(data, 0) }
          )}
        </TreeItem>
        <TreeItem key={`total`} className={`total`} nodeId={`total`}
          label={<Row>
            <Cell>
              <b>Total</b>
            </Cell>
            <Cell>
              <b>{format(visitsAverageDuration)}</b>
            </Cell>
            <Cell>
              <b>{format(visitsTotalDuration)}</b>
            </Cell>
            <Cell>
              <b>{visitsTotalCount}</b>
            </Cell>
          </Row>} >
        </TreeItem>
      </TreeView>
    </div>
  );
}

export default App;
